/* eslint-disable react/jsx-no-bind */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import { useI18next } from 'gatsby-plugin-react-i18next';
// UI lib components
import { Col, Container, Row, Visible } from 'react-grid-system';

// UI local components
import { useTranslation } from 'react-i18next';
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';
import BannerSection from '../page-components/page-solutions/banner';
import BeardCrumbs from '../page-components/breadCrumbs';

// Assests
import BANNER_IMG from '../images/partners/partnersBanner.png';
import NEXT_ARROW from '../images/index/icons/blue-arrow.svg';
import PREV_ARROW from '../images/detail-reference/prev-arrow.svg';
import NEXT_ARROW_CAROUSEL from '../images/detail-reference/next-arrow.svg';

// Styles
import '../page-styles/partners.scss';

/* -------------------------------------------------------------------------- */
/*                            All Partners Page                               */
/* -------------------------------------------------------------------------- */

function AllPartnersPage() {
  /* ------------------------------- CONSTANSTS ----------------------------- */

  /* ********************************** HOOKS ********************************* */
  const [partners, setPartners] = useState([]);
  const [loader, setLoader] = useState(true);
  const { language } = useI18next();
  /* -------------------------------- CONST --------------------------------- */
  const SITE_TITLE = 'LUXOR TECHNOLOGY AND TRADE | Partners';
  // eslint-disable-next-line operator-linebreak
  const SITE_DESCRIPTION =
    // eslint-disable-next-line max-len
    'Luxor technolgie & Trade est une société Tunisienne Spécialisée dans le développement des solutions techniques pour la construction. ';

  useEffect(() => {
    if (language === 'fr') {
      axios
        .get(
          // eslint-disable-next-line max-len
          'https://admin.luxor.com.tn/api/1.1/tables/partner/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c',
        )
        .then(({ data: { data } }) => {
          setLoader(true);
          setPartners(data);
        })

        .then(() => {
          setLoader(false);
        })
        .catch((error) => error);
    } else if (language === 'en') {
      axios
        .get(
          // eslint-disable-next-line max-len
          'https://admin.luxor.com.tn/api/1.1/tables/partneren/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c',
        )
        .then(({ data: { data } }) => {
          setLoader(true);
          setPartners(data);
        })

        .then(() => {
          setLoader(false);
        })
        .catch((error) => error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  function getSliderArrowPrev(clickHandler, hasPrev) {
    if (hasPrev) {
      return (
        <button
          className="carousel-icon-prev"
          type="button"
          name="carousel-icon-prev"
          onClick={clickHandler}
        >
          <img src={PREV_ARROW} alt="previous-arrow" />
        </button>
      );
    }
    return '';
  }

  function getSliderArrowNext(clickHandler, hasNext) {
    if (hasNext) {
      return (
        <button
          className="carousel-icon-next"
          type="button"
          name="carousel-icon-next"
          onClick={clickHandler}
        >
          <img src={NEXT_ARROW_CAROUSEL} alt="next-arrow" />
        </button>
      );
    }
    return '';
  }
  //  localization
  const { t } = useTranslation();
  const { title, titleBreadcrumbs, siteWeb } = t('partners', {
    returnObjects: true,
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout loader={loader}>
      <Seo title={SITE_TITLE} description={SITE_DESCRIPTION} />
      <Container className="partners-page fluid" fluid>
        <BannerSection bannerImage={BANNER_IMG}>
          <h1>{title}</h1>
        </BannerSection>
        <Visible lg xl xxl>
          <Container className="" fluid>
            <BeardCrumbs path="partners" titleBreadcrumbs={titleBreadcrumbs} />
          </Container>
        </Visible>
        <Visible lg xl xxl>
          <Container className="all-partners fluid max-w">
            <Row className="fluid">
              {partners
                ?.sort((elm1, elm2) => elm1.rang - elm2.rang)
                .map((element) => (
                  <Col
                    className="single-partner-container fluid"
                    xxl={3}
                    xl={3}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Container
                      className="single-partner text-center fluid"
                      fluid
                    >
                      <img
                        src={`https://admin.luxor.com.tn/${element.logo?.data.url}`}
                        alt=""
                      />
                      <p>{element.description}</p>
                      <a
                        className="website-link flex items-center justify-center"
                        href={element.website_text}
                        target="blank"
                      >
                        <h3>{siteWeb}</h3>
                        <img src={NEXT_ARROW} alt="next-arrow" />
                      </a>
                    </Container>
                  </Col>
                ))}
            </Row>
          </Container>
        </Visible>
        <Visible sm md xs>
          <Container className="details-reference-container max-w top">
            <Carousel
              className="reference-carousel"
              showIndicators={false}
              showArrows
              useKeyboardArrows
              emulateTouch
              interval={5000}
              width="80%"
              renderArrowPrev={getSliderArrowPrev}
              renderArrowNext={getSliderArrowNext}
              showThumbs={false}
              showStatus={false}
              swipeable
            >
              {partners
                ?.sort((elm1, elm2) => elm1.rang - elm2.rang)
                .map((element) => (
                  <Col
                    className="single-partner-container fluid"
                    xxl={3}
                    xl={3}
                    lg={4}
                    md={6}
                    sm={12}
                    xs={12}
                  >
                    <Container
                      className="single-partner text-center fluid"
                      fluid
                    >
                      <img
                        src={`https://admin.luxor.com.tn/${element.logo?.data.url}`}
                        alt=""
                      />
                      <p>{element.description}</p>
                      <a
                        className="website-link flex items-center justify-center"
                        href={element.website_text}
                        target="blank"
                      >
                        <h3>SITE WEB</h3>
                        <img src={NEXT_ARROW} alt="next-arrow" />
                      </a>
                    </Container>
                  </Col>
                ))}
            </Carousel>
          </Container>
        </Visible>
      </Container>
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["Partners", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default AllPartnersPage;
